export interface ServiceStageOption {
  label: string;
  code: string[];
}

export interface OperationCategories {
  [key: string]: OperationCategory
}

export interface OperationCategory {
  title: string;
  sort: number;
  icon: string;
  colorCodes: {
    [key: string]: number[]
  };
  color?: string;
}

export default {
  serviceStageOptions: [
    {
      label: 'Alarm',
      code: ['0_ALERT_DETECTED']
    },
    {
      label: 'Diagnosing',
      code: ['0_DIAGNOSING']
    },
    {
      label: 'Field Operation',
      code: ['0_INSTALLING']
    },
    {
      label: 'Calibration',
      code: ['1_TO_CALIBRATE']
    },
    {
      label: 'Validation',
      code: ['3_OPERATIVE']
    },
    {
      label: 'Live',
      code: ['4_LIVE', '5_FINETUNED']
    }
  ],
  operationCategories: {
    energy: {
      title: 'Energy',
      sort: 3,
      icon: 'far fa-solar-panel',
      colorCodes: {
        green: [3024],
        yellow: [3012, 3006, 3003, 3002, 3001],
        red: [3000]
      }
    },
    status: {
      title: 'Device',
      sort: 1,
      icon: 'far fa-heartbeat',
      colorCodes: {
        green: [1100, 1200],
        yellow: [1010, 1030],
        red: [1000, 1001, 1002]
      }
    },
    valid: {
      title: 'Data',
      sort: 2,
      icon: 'far fa-chart-bar',
      colorCodes: {
        green: [4000],
        yellow: [4001],
        red: [4002]
      }
    }
  }
}
