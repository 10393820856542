
import { defineComponent, PropType } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { User } from '@/types/user'
import { Bin, Recipe, DeviceReading, FetchBinError, DeviceConfigTime } from '@/types/bin'
import { Area } from '@/types/area'
import Loading from '@/components/shared/Loading.vue'
import HeaderBar from '@/components/shared/Header.vue'
import BinOperationsStatus from '@/components/shared/BinOperationsStatus.vue'
import dateTimeFormatting from '@/common/mixins-date-time'
import calculations from '@/common/mixins-calculations'
import utilities from '@/common/mixins-utilities'
import BinColorConfig from '@/common/bin-color-config'
import calculateEstimatedReading from '@/common/calculateEstimatedReading'

export default defineComponent({
  name: 'BinDetails',
  components: {
    Loading,
    HeaderBar,
    BinOperationsStatus
  },
  mixins: [
    dateTimeFormatting,
    calculations,
    utilities,
    calculateEstimatedReading
  ],
  props: {
    uuId: {
      type: String as PropType<string>,
      required: true
    }
  },
  data () {
    return {
      operator: JSON.parse(localStorage.getItem('insylo_operator') || '{}') as User,
      operatorType: localStorage.getItem('insylo_operatorType') as string,
      operatorPermissions: [] as string[],
      currentBin: {} as Bin,
      currentArea: {} as Area,
      binDeviceWithRecipe: {} as DeviceReading,
      deviceRecipe: {} as Recipe,
      binDeviceLastReading: {} as DeviceReading,

      // utility
      dataReady: false,
      loading: false,
      binStatusVisible: false,

      // Next datapoint expected time
      deviceConfig: {} as DeviceConfigTime | undefined
    }
  },
  computed: {
    getSiloImage (): string {
      return BinColorConfig(
        this.currentBin,
        // this.binVolumeRemainingPercentage(this.binDeviceLastReading.volume, this.currentBin.specs.estimatedVolume),
        this.binCapacityRemainingPercentage(this.currentBin.specs.estimatedVolume, this.binDeviceLastReading.density, this.binDeviceLastReading.weight, this.currentBin.weight.fixedMaxCapacity),

        'binImg'
      )
    }
  },
  created () {
    this.setOperatorPermissions()
    this.setBinData()
    
  },
  methods: {
    ...mapActions(['getBin', 'getArea', 'getBinDeviceForRecipe', 'getRecipe', 'getBinDeviceLastReading', 'getdeviceConfigTime']),
    ...mapMutations(['setFetchBinError']),

    setOperatorPermissions () {
      if (this.operatorType === 'Subuser') {
        this.operatorPermissions = JSON.parse(localStorage.getItem('insylo_operatorSubuserPermissions') || '')
      }
    },

    setDeviceConfigData () {
      return this.getdeviceConfigTime(this.currentBin.uuid).then(response => {
        const timeLocal = dateTimeFormatting.methods.showHoursInOperatorTimeZone(response.data.timeWindowStart, response.data.timeWindowEnd)
        this.deviceConfig = { 
          id:response.data.id, 
          frequency:response.data.period, 
          timeWindowStart: timeLocal[0],
          timeWindowEnd: timeLocal[1],
          uuid:response.data.uuid }
      }).catch(error => {
        console.log(error.response.data.msg)
        this.deviceConfig = undefined
      })
    },
    async setBinData () {
      this.loading = true
      await this.fetchBin()
      if (!this.currentBin.uuid) return
      await this.fetchArea()
      await this.fetchBinDeviceForRecipe()
      await  this.setDeviceConfigData()
      if (this.binDeviceWithRecipe.recipeId && this.canViewRecipe()) {
        await this.fetchRecipe()
      }
      await this.fetchBinDeviceLastReading()
      this.dataReady = true
      this.loading = false
    },
    fetchBin () {
      return this.getBin(this.uuId).then(response => {
        this.currentBin = response.data
      }).catch(error => {
        if (error.response.status === 404) {
          const binError: FetchBinError = {
            status: error.response.status,
            title: 'Bin not found',
            description: 'It does not exist or you don\'t have a permission'
          }
          this.setFetchBinError(binError)
          this.$router.push('/error')
        }
      })
    },
    fetchArea () {
      return this.getArea(this.currentBin.areaId).then(response => {
        this.currentArea = response.data
      }).catch(error => {
        console.log(error.response.data.msg)
      })
    },
    fetchBinDeviceForRecipe () {
      return this.getBinDeviceForRecipe(this.uuId).then(response => {
        if (response.data.data) {
          this.binDeviceWithRecipe = response.data.data[0]
        }
      }).catch(error => {
        console.log(error.response.data.msg)
      })
    },
    fetchRecipe () {
      return this.getRecipe(this.binDeviceWithRecipe.recipeId).then(response => {
        this.deviceRecipe = response.data
      }).catch(error => {
        console.log(error.response.data.msg)
      })
    },
    fetchBinDeviceLastReading () {
      return this.getBinDeviceLastReading(this.uuId).then(response => {
        if (response.data.data) {
          this.binDeviceLastReading = response.data.data[0]
        }
      }).catch(error => {
        console.log(error.response.data.msg)
      })
    },

    // shortened methods called from mixins
    isBinFullOrEmpty (): string {
      return this.binFullEmpty(
        this.binDeviceLastReading.volume,
        this.currentBin.specs.estimatedVolume,
        this.currentBin.specs.fullThresholdPercent,
        this.currentBin.specs.emptyThresholdPercent
      )
    },
    binTextColorCss (): string {
      return BinColorConfig(
        this.currentBin,
        // this.binVolumeRemainingPercentage(this.binDeviceLastReading.volume, this.currentBin.specs.estimatedVolume),
        this.binCapacityRemainingPercentage(this.currentBin.specs.estimatedVolume, this.binDeviceLastReading.density, this.binDeviceLastReading.weight, this.currentBin.weight.fixedMaxCapacity),

        'binTextColor'
      )
    },

    isOperationGood () {
      if (this.currentBin.data.operations && this.currentBin.data.operations.status && this.currentBin.data.operations.status.status) {
        const status = this.currentBin.data.operations.status.status
        return (status !== '0_ALERT_DETECTED')
      }
      return true
    },
    // if device is not sending data for more than 24h
    deviceNotSendingData (): boolean {
      if (this.currentBin.data.stock && this.currentBin.data.stock.last_update) {
        const sinceLastReading = (Date.now() - Date.parse(this.timeInOperatorTimezone(this.currentBin.data.stock.last_update))) / 3600000
        if (sinceLastReading > 24) return true
      }
      return false
    },

    // cameras
    rgbImage (): string {
      if (this.binDeviceLastReading.rgb) {
        return this.binDeviceLastReading.rgb
      } else if (this.binDeviceLastReading.a) {
        return this.binDeviceLastReading.a
      }
      return ''
    },
    depthMapImage (): string {
      if (this.binDeviceLastReading.depthMapImage) {
        return this.binDeviceLastReading.depthMapImage
      }
      return ''
    },

    // permissions
    canViewRecipe (): boolean {
      if (this.operatorType === 'Subuser') {
        return Boolean(this.operatorPermissions.includes('viewRecipes'))
      }
      return true
    }
  }
})
