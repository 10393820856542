
import { defineComponent, PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Bin, BinOperationStatus } from '@/types/bin'
import binStatusOptions, { ServiceStageOption, OperationCategories, OperationCategory } from '../../common/bin-status-options'

export default defineComponent ({
  name: 'BinOperationsStatus',
  props: {
    bin: {
      type: Object as PropType<Bin>,
      required: true
    }
  },
  data () {
    return {
      currentStatuses: [] as (BinOperationStatus & OperationCategory)[],
      serviceStages: binStatusOptions.serviceStageOptions as ServiceStageOption[],
      operationHelperData: binStatusOptions.operationCategories as OperationCategories
    }
  },
  computed: {
    ...mapGetters(['binOperationStatuses'])
  },
  created () {
    if (!this.binOperationStatuses.length) {
      this.fetchStatuses()
    } else {
      this.initCurrentStatuses()
    }
  },
  methods: {
    ...mapActions(['getStatuses']),

    async fetchStatuses () {
      await this.getStatuses()
      return this.initCurrentStatuses()
    },

    initCurrentStatuses () {
      this.setCurrentStatuses()
      this.colorCurrentStatuses()
      this.reorderCurrentStatuses()
    },
    setCurrentStatuses () {
      for (const operationCategory in this.bin.data.operations) {
        if (operationCategory === 'install') continue
        const status: BinOperationStatus[] = this.binOperationStatuses.filter((status: BinOperationStatus) => {
          return this.bin.data.operations[operationCategory].code === status.code
        })
        status[0].category = operationCategory
        const extendedStatus: BinOperationStatus & OperationCategory = {
          ...status[0],
          ...this.operationHelperData[operationCategory]
        }
        this.currentStatuses.push(extendedStatus)
      }
    },
    colorCurrentStatuses () {
      this.currentStatuses = this.currentStatuses.map(status => {
        for (const color in status.colorCodes) {
          if (status.colorCodes[color].includes(status.code)) status.color = color
        }
        return status
      })
    },
    reorderCurrentStatuses () {
      this.currentStatuses = this.currentStatuses.sort((a, b) => {
        return a.sort - b.sort
      })
    },

    operationIcon (status: BinOperationStatus & OperationCategories) {
      return status.icon + ' ' + 'textColor-' + status.color
    },

    // utilities
    removeUnderscoreFromText (type: string) {
      return type.replace(/_/g, ' ')
    }
  }
})
