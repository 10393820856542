import dateTimeFormatting from './mixins-date-time'
import { DeviceConfigTime } from '@/types/bin'

const timeInOperatorTimezone = (dateTime: Date, format = '') => dateTimeFormatting.methods.timeInOperatorTimezone(dateTime.toISOString(), format)
const DEVICE_WAKE_UP_TIME = 75
let deviceVersion = '2.2'

export default {
  methods: {
    calculateEstimatedReading (version: string, deviceConfigTime: DeviceConfigTime, binLastReading: string, dateNow: Date): string {
      // This method is the entry point
      deviceVersion = version
      let estimatedNextReading = new Date(binLastReading)
      let timeUntilNextReading = this.calculateNextCaptureFrequency(deviceConfigTime.frequency)
      this.addMinutes(estimatedNextReading, timeUntilNextReading)
      // Calculate the next estimated reading bigger than current time
      while (estimatedNextReading <= dateNow) {
        // If estimated current time it's outside time window add device wake time else add frequency
        timeUntilNextReading = !this.checkEstimatedReadingWithinInterval(deviceConfigTime.timeWindowStart, deviceConfigTime.timeWindowEnd, estimatedNextReading)
          ? this.defineDeviceWakeUpTimeByVersion() : this.calculateNextCaptureFrequency(deviceConfigTime.frequency)
        estimatedNextReading = this.addMinutes(estimatedNextReading, timeUntilNextReading)
      }
      // If estimated next reading is outside the time window add time until get in window
      estimatedNextReading = this.calculateEstimatedReadingDateIfOutOfRange(estimatedNextReading, deviceConfigTime.timeWindowStart, deviceConfigTime.timeWindowEnd)
      return timeInOperatorTimezone(estimatedNextReading, 'time')
    },
    checkEstimatedReadingWithinInterval (startInterval:number, endInterval:number, estimatedReading:Date): boolean {
      // Check if the next reading will be within the time window
      if (startInterval < endInterval) { // Normal window
        return estimatedReading.getHours() >= startInterval && estimatedReading.getHours() < endInterval
      } else { // Window between pm and am
        return estimatedReading.getHours() < endInterval || (estimatedReading.getHours() >= startInterval && estimatedReading.getHours() < 24)
      }
    },
    calculateNextCaptureFrequency (frequency:number): number {
      // Calculates the frequency with the configured frequency and the actual time divice wake up of 75 minutes
      return this.defineDeviceWakeUpTimeByVersion() * (Math.ceil(frequency / this.defineDeviceWakeUpTimeByVersion()))
    },
    calculateEstimatedReadingDateIfOutOfRange (estimatedNextReading: Date, startInterval: number, endInterval: number): Date {
      // From estimated next reading value out of range calculate new estimated next reading inside range
      while (!this.checkEstimatedReadingWithinInterval(startInterval, endInterval, estimatedNextReading)) {
        estimatedNextReading = this.addMinutes(estimatedNextReading, this.defineDeviceWakeUpTimeByVersion())
      }
      return estimatedNextReading
    },
    addMinutes (time : Date, minutes : number): Date {
      time.setMinutes(time.getMinutes() + minutes)
      return time
    },
    calculateReadingsPerDay (deviceConfigTime: DeviceConfigTime): number {
      // Return expected readings per day from deviceConfigTime
      if (deviceConfigTime.timeWindowEnd <= deviceConfigTime.timeWindowStart) {
        deviceConfigTime.timeWindowEnd += 24
      }
      const timeAuthorized = Math.abs(deviceConfigTime.timeWindowEnd - deviceConfigTime.timeWindowStart)
      return Math.ceil((timeAuthorized * 60) / this.calculateNextCaptureFrequency(deviceConfigTime.frequency))
    },
    // Sets the deviceWakeUpTime variable based on the provided version number
    defineDeviceWakeUpTimeByVersion (): number {
      // With an input like [2,1] this checks that the version is greater than 2.2
      const versionNumbers = this.transformDeviceVersion()
      if (versionNumbers.length >= 1 && (versionNumbers[0] > 2 || (versionNumbers[0] === 2 && versionNumbers[1] > 2))) {
        return DEVICE_WAKE_UP_TIME
      }
      return DEVICE_WAKE_UP_TIME + 5
    },
    // Transform version from "insylo.silo.2.2.13" or "2.2.5.9" format to [2, 2, 13] [2, 2, 5, 9] format
    transformDeviceVersion () : number[] {
      const regex = /\d+/g // Regular expression to match numbers
      const matches = deviceVersion.match(regex) // Find all matches in the string
      if (matches) {
        return matches.map(Number) // Convert matched strings to numbers
      }
      return []
    }
  }
}